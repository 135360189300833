<template>
  <div class="admin-innovations">
    <h1 ref="top">
      ✎ <b>Innovationsfeld</b> hinzufügen oder bearbeiten ({{ state.innovations.length }})
    </h1>
    <div class="col">
      <div class="row">
        <form class="admin-form" @submit.prevent>
          <label>
            <span>Titel</span>
            <input v-model="state.innovation.title" type="text" />
          </label>
          <label>
            <span>
              Bild
              <small>Auflösung: 1152*720 px/ Format: .jpg/ Größe: max 0.5 MB</small>
            </span>
            <img
                :src="`${state.basepath}images/${state.innovation.image}`"
                :alt="state.innovation.title"
                class="img"
                v-if="state.innovation.image && !state.previewImage">
            <div
                class="preview-image"
                v-if="state.previewImage">
              <img
                  :src="state.previewImage"
                  class="img" />
              <svg
                  class="btn-remove"
                  @click="removePreviewImage"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <input type="file" @change="onFileChange" ref="file">
          </label>
          <label>
            <span>
              Kurzbeschreibung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorDescription"
                v-model:content.value="state.innovation.description" />
          </div>
          <label>
            <span>
              Weiterführende Links
              <small>optional, inkl. http:// bzw. https://</small>
            </span>
            <div
                v-for="(input, index) in state.innovation.additionalLinks"
                :key="`link-${index}`"
                class="input-container">
              <input v-model="state.innovation.additionalLinks[index]" type="text" />
              <svg
                  class="btn-remove"
                  @click="removeLink(index, state.innovation.additionalLinks)"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <svg
                class="btn-add"
                @click="addLink(state.innovation.additionalLinks)"
                viewBox="0 0 24 24">
              <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
            </svg>
          </label>
          <label>
            <span>
              Anwendungsfelder
              <small>250 - 500 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorFields"
                v-model:content.value="state.innovation.fields" />
          </div>
          <label>
            <span>
              Datenstrom
              <small>250 - 500 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorData"
                v-model:content.value="state.innovation.data" />
          </div>
          <label>
            <span>
              Integration in das Autohaus
              <small>250 - 500 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorIntegration"
                v-model:content.value="state.innovation.integration" />
          </div>
          <label>
            <span>
              Chancen
              <small>250 - 500 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorChances"
                v-model:content.value="state.innovation.chances" />
          </div>
          <label>
            <span>
              Kosten
              <small>250 - 500 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorCosts"
                v-model:content.value="state.innovation.costs" />
          </div>
          <label>
            <span>
              Verweise auf Innnovationsfelder
              <small>Mehrfachauswahl möglich</small>
            </span>
          </label>
          <div class="multiselect-container multiselect-innovations">
            <Multiselect
                mode="tags"
                placeholder="Innnovationsfelder auswählen"
                :searchable="true"
                valueProp="id"
                label="title"
                track-by="title"
                v-model="state.innovation.innovations"
                :options="state.innovations">
              <template v-slot:nooptions>
                <div class="multiselect-no-options">
                  Es wurden noch keine Innovationsfelder angelegt.
                </div>
              </template>
              <template v-slot:noresults>
                <div class="multiselect-no-results">
                  Keine Ergebnisse gefunden :(
                </div>
              </template>
            </Multiselect>
          </div>
          <label>
            <span>
              Produkte/ Innnovationspartner
              <small>Mehrfachauswahl möglich</small>
            </span>
          </label>
          <div class="multiselect-container multiselect-products">
            <Multiselect
                mode="tags"
                placeholder="Produkte/ Innnovationspartner auswählen"
                :searchable="true"
                valueProp="id"
                label="title"
                track-by="title"
                v-model="state.innovation.products"
                :options="state.products">
              <template v-slot:nooptions>
                <div class="multiselect-no-options">
                  Es wurden noch keine Produkte/ Innnovationspartner angelegt.
                </div>
              </template>
              <template v-slot:noresults>
                <div class="multiselect-no-results">
                  Keine Ergebnisse gefunden :(
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="buttons-admin">
            <button
                class="btn-admin"
                v-if="state.mode === 'add'"
                @click="addInnovation()">
              <span>＋</span> Hinzufügen
            </button>
            <button
                class="btn-admin"
                v-if="state.mode === 'edit'"
                @click="updateInnovation(state.innovation.id)">
              <span>✔</span> Speichern
            </button>
            <button
                class="btn-admin btn-admin-cancel"
                v-if="state.mode === 'edit'"
                @click="cancelEdit()">
              <span>✖</span> Abbrechen
            </button>
          </div>
        </form>
      </div>
      <div class="row">
        <p v-if="state.innovations.length === 0">
          Keine Innovationsfelder angelegt.
        </p>
        <input
            v-if="state.innovations.length !== 0"
            type="text"
            placeholder="Suche..."
            v-model="state.search"
            class="admin-search" />
        <ul class="innovations">
          <li
              v-for="(innovation, index) in searchedItems"
              :key="innovation.id">
            <h3>{{ innovation.title }}</h3>
            <b>{{ index + 1 }}</b>
            <div class="buttons-admin toolbar">
              <button
                  class="btn-admin btn-admin-sm"
                  @click="editInnovation(innovation.id)">✎ Editieren</button>
              <button
                  class="btn-admin btn-admin-sm btn-admin-danger"
                  @click="deleteInnovation(innovation.id)">✖ Löschen</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive, ref, computed, nextTick } from 'vue'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// https://github.com/vueform/multiselect
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  name: 'Admin innovations',

  components: {
    Multiselect
  },

  setup() {
    const instance = getCurrentInstance()
    const emitter = instance.appContext.config.globalProperties.emitter
    const axios = instance.appContext.config.globalProperties.axios
    const top = ref(null)
    const file = ref(null)
    const editorDescription = ref(null)
    const editorFields = ref(null)
    const editorData = ref(null)
    const editorIntegration = ref(null)
    const editorChances = ref(null)
    const editorCosts = ref(null)

    const state = reactive({
      basepath: process.env.VUE_APP_API_ENDPOINT,
      mode: 'add',
      search: '',
      innovations: [],
      products: [],
      innovation: {
        id: null,
        title: '',
        image: null,
        imageRaw: null,
        description: '',
        additionalLinks: [],
        fields: '',
        data: '',
        integration: '',
        chances: '',
        costs: '',
        innovations: [],
        products: []
      },
      previewImage: null
    })

    const clear = () => {
      state.mode = 'add'
      state.innovation = {
        id: null,
        title: '',
        image: null,
        imageRaw: null,
        description: '',
        additionalLinks: [],
        fields: '',
        data: '',
        integration: '',
        chances: '',
        costs: '',
        innovations: [],
        products: []
      }
      state.previewImage = null
      file.value.value = ''
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
      updateEditor()
    }

    const updateEditor = () => {
      editorDescription.value.getQuill().enable(false)
      editorDescription.value.setHTML(state.innovation.description)
      editorFields.value.getQuill().enable(false)
      editorFields.value.setHTML(state.innovation.fields)
      editorData.value.getQuill().enable(false)
      editorData.value.setHTML(state.innovation.data)
      editorIntegration.value.getQuill().enable(false)
      editorIntegration.value.setHTML(state.innovation.integration)
      editorChances.value.getQuill().enable(false)
      editorChances.value.setHTML(state.innovation.chances)
      editorCosts.value.getQuill().enable(false)
      editorCosts.value.setHTML(state.innovation.costs)
      // activate all
      nextTick(() => {
        editorDescription.value.getQuill().enable(true)
        editorFields.value.getQuill().enable(true)
        editorData.value.getQuill().enable(true)
        editorIntegration.value.getQuill().enable(true)
        editorChances.value.getQuill().enable(true)
        editorCosts.value.getQuill().enable(true)
      })
    }

    const searchedItems = computed(() => state.innovations.filter((item) => (
      item.title.toLowerCase().indexOf(state.search.toLowerCase()) != -1
    )))

    const addLink    = (type) => type.push('')
    const removeLink = (index, type) => type.splice(index, 1)

    const createPreviewImage = (file) => {
      const reader = new FileReader()
      reader.onload = (e) => state.previewImage = e.target.result
      reader.readAsDataURL(file)
    }

    const removePreviewImage = (e) => {
      e.preventDefault()
      file.value.value = ''
      state.innovation.imageRaw = null
      state.previewImage = null
    }

    const onFileChange = (e) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Bild ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.innovation.imageRaw = null
        return
      }
      state.innovation.imageRaw = files[0]
      createPreviewImage(files[0])
    }

    const errorMsg = (error) => {
      createToast(
        {
          title: 'Es gab einen Fehler!',
          description: '' + error
        },
        {
          type: 'danger',
          position: 'bottom-right',
          showIcon: true
        }
      )
      emitter.emit('loading-stop')
    }

    // GET
    const getInnovations = () => {
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}innovations`)
        .then(response => {
          state.innovations = response.data
          createToast(
            'Innovationsfelder geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
        })
        .catch(error => errorMsg(error))
    }

    // GET
    const getProducts = () => {
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}products`)
        .then(response => state.products = response.data)
        .catch(error => errorMsg(error))
    }

    // ADD
    const addInnovation = () => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}innovations`, buildFormData(state.innovation))
        .then(() => {
          createToast(
            'Innovationsfeld hinzugefügt!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getInnovations()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // EDIT
    const editInnovation = (id) => {
      emitter.emit('loading-start')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}innovations/${id}`)
        .then((response) => {
          state.mode = 'edit'
          state.innovation = response.data
          updateEditor()
          createToast(
            {
              title: 'Innovationsfeld geladen!',
              description: 'Nutzen Sie das Formular zum Editieren!'
            },
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // UPDATE
    const updateInnovation = (id) => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}innovations/${id}`, buildFormData(state.innovation))
        .then(() => {
          createToast(
            'Innovationsfeld geändert!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getInnovations()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // CANCEL
    const cancelEdit = () => {
      clear()
      createToast(
        {
          title: 'Bearbeitung abgebrochen!'
        },
        { type: 'default', position: 'bottom-right', showIcon: true }
      )
    }

    // DELETE
    const deleteInnovation = (id) => {
      if (confirm(`Innovationsfeld wirklich löschen?`)) {
        emitter.emit('loading-start')
        axios
          .delete(`${process.env.VUE_APP_API_ENDPOINT}innovations/${id}`)
          .then(() => {
            createToast(
              'Innovationsfeld gelöscht!',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            clear()
            getInnovations()
            emitter.emit('loading-stop')
          })
          .catch(error => errorMsg(error))
      }
    }

    onMounted(() => {
      getInnovations()
      getProducts()
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
    })

    return {
      searchedItems,
      state,
      top,
      file,
      onFileChange,
      addInnovation,
      editInnovation,
      updateInnovation,
      cancelEdit,
      deleteInnovation,
      removePreviewImage,
      addLink,
      removeLink,
      editorDescription,
      editorFields,
      editorData,
      editorIntegration,
      editorChances,
      editorCosts
    }
  }
}
</script>


<style lang="scss" scoped>
.admin-innovations {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.innovations {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background: linear-gradient(to right, rgb(255, 148, 114), rgb(242, 112, 156));
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: rgb(0, 0, 50), $alpha: 0.1);
    position: relative;
    overflow: hidden;
    transition: all 150ms ease-in-out;
    border-bottom: 1pt solid rgba($color: #000, $alpha: 0.1);

    b {
      position: absolute;
      right: 15%;
      bottom: -40px;
      color: rgba($color: #fff, $alpha: 0.1);
      font-size: 128px;
      line-height: 100%;
      z-index: 0;
      pointer-events: none;
    }

    &:hover {
      box-shadow: 0 0 30px rgba($color: rgb(0, 0, 50), $alpha: 0.3);
      transform: scale(1.025);

      .toolbar {
        transform: translate(-50%, 0%);
      }
    }
  }
}

.preview-image {
  position: relative;
  pointer-events: none;

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 150ms ease-in-out;
    background: #fff;
    border-radius: 50%;
    pointer-events: all;
    z-index: 10;

    path {
      fill: #e24545;
    }

    &:hover {
      path {
        fill: lighten(#e24545, 20%);
      }
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 50, 0.75);
  padding: 10px 5px;
  transition: all 150ms ease-in-out;
  transform: translate(-50%, -100%);
  border-radius: 0 0 10px 10px;
}

.multiselect-container.multiselect-innovations {
  :deep(.multiselect-tag) {
    background: linear-gradient(to right, rgb(255, 148, 114), rgb(242, 112, 156));
    color: #000;

    i::before {
      color: #000;
    }
  }
}

.multiselect-container.multiselect-products {
  :deep(.multiselect-tag) {
    background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
    color: #000;

    i::before {
      color: #000;
    }
  }
}
</style>
