<template>
  <div class="admin-products">
    <h1 ref="top">
      ✎ <b>Produkt/ Innovationspartner</b> hinzufügen oder bearbeiten ({{ state.products.length }})
    </h1>
    <div class="col">
      <div class="row">
        <form class="admin-form" @submit.prevent>
          <label>
            <span>Produktname</span>
            <input v-model="state.product.title" type="text" />
          </label>
          <label>
            <span>
              Produktbild
              <small>Format: .jpg/ Größe: max 0.5 MB</small>
            </span>
            <img
                :src="`${state.basepath}images/${state.product.image}`"
                :alt="state.product.title"
                class="img"
                v-if="state.product.image && !state.previewImage">
            <div
                class="preview-image"
                v-if="state.previewImage">
              <img
                  :src="state.previewImage"
                  class="img" />
              <svg
                  class="btn-remove"
                  @click="removePreviewImage"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <input type="file" @change="onImageChange" ref="fileImage">
          </label>
          <label>
            <span>Hersteller</span>
            <input v-model="state.product.brand" type="text" />
          </label>
          <label>
            <span>
              Logo
              <small>Format: .jpg/ Größe: max 0.5 MB</small>
            </span>
            <img
                :src="`${state.basepath}images/${state.product.logo}`"
                :alt="state.product.title"
                class="img"
                v-if="state.product.logo && !state.previewLogo">
            <div
                class="preview-image"
                v-if="state.previewLogo">
              <img
                  :src="state.previewLogo"
                  class="img" />
              <svg
                  class="btn-remove"
                  @click="removePreviewLogo"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <input type="file" @change="onLogoChange" ref="fileLogo">
          </label>
          <label>
            <span>
              E-Mail
              <small>Empfänger des Kontaktwunsches</small>
            </span>
            <input v-model="state.product.email" type="text" />
          </label>
          <label>
            <span>
              YouTube-ID (kein Link)
              <small>youtube.com/watch?v=<b>Yg0k8ylbZe0</b></small>
            </span>
            <input v-model="state.product.ytId" type="text" />
          </label>
          <label>
            <span>
              Kurzbeschreibung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorShortDescription"
                v-model:content.value="state.product.shortDescription" />
          </div>
          <label>
            <span>
              Beschreibung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorDescription"
                v-model:content.value="state.product.description" />
          </div>
          <label>
            <span>
              USP
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorUsp"
                v-model:content.value="state.product.usp" />
          </div>
          <label v-if="state.mode === 'add'">
            <span>
              Bildergalerie
            </span>
            <div
                v-for="(input, index) in state.product.galleryRaw"
                :key="`link-${index}`"
                class="media-container">
              <div class="preview-image">
                <img :src="state.galleryPreviews[index]" v-if="state.galleryPreviews[index]" />
              </div>
              <div class="inputs">
                <input type="file" @change="e => onMediaChange(e, index)">
              </div>
              <svg
                  class="btn-remove"
                  @click="e => removeMedia(e, index)"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
          </label>
          <label v-if="state.mode === 'add'" style="margin-top: -50px">
            <svg
                class="btn-add"
                @click="addMedia(state.product.galleryRaw)"
                viewBox="0 0 24 24">
              <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
            </svg>
          </label>
          <div class="buttons-admin">
            <button
                class="btn-admin"
                v-if="state.mode === 'add'"
                @click="addProduct()">
              <span>＋</span> Hinzufügen
            </button>
            <button
                class="btn-admin"
                v-if="state.mode === 'edit'"
                @click="updateProduct(state.product.id)">
              <span>✔</span> Speichern
            </button>
            <button
                class="btn-admin btn-admin-cancel"
                v-if="state.mode === 'edit'"
                @click="cancelEdit()">
              <span>✖</span> Abbrechen
            </button>
          </div>
        </form>
        <AdminMediaGallery
            v-if="state.mode === 'edit'"
            :productId="state.product.id" />
      </div>
      <div class="row">
        <p v-if="state.products.length === 0">
          Keine Produkte angelegt.
        </p>
        <input
            v-if="state.products.length !== 0"
            type="text"
            placeholder="Suche..."
            v-model="state.search"
            class="admin-search" />
        <ul class="products">
          <li
              v-for="(product, index) in searchedItems"
              :key="product.id">
            <span v-if="product.brand">{{ product.brand }}</span>
            <h3>{{ product.title }}</h3>
            <b>{{ index + 1 }}</b>
            <div class="buttons-admin toolbar">
              <button
                  class="btn-admin btn-admin-sm"
                  @click="editProduct(product.id)">✎ Editieren</button>
              <button
                  class="btn-admin btn-admin-sm btn-admin-danger"
                  @click="deleteProduct(product.id)">✖ Löschen</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive, ref, computed, nextTick } from 'vue'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import AdminMediaGallery from '@/components/AdminMediaGallery'

export default {
  name: 'Admin products',

  components: {
    AdminMediaGallery
  },

  setup() {
    const instance = getCurrentInstance()
    const emitter = instance.appContext.config.globalProperties.emitter
    const axios = instance.appContext.config.globalProperties.axios
    const top = ref(null)
    const fileLogo = ref(null)
    const fileImage = ref(null)
    const editorShortDescription = ref(null)
    const editorDescription = ref(null)
    const editorUsp = ref(null)

    const state = reactive({
      basepath: process.env.VUE_APP_API_ENDPOINT,
      mode: 'add',
      search: '',
      products: [],
      product: {
        id: null,
        title: '',
        logo: null,
        logoRaw: null,
        image: null,
        imageRaw: null,
        brand: '',
        email: '',
        shortDescription: '',
        description: '',
        ytId: '',
        usp: '',
        galleryRaw: []
      },
      previewLogo: null,
      previewImage: null,
      galleryPreviews: []
    })

    const clear = () => {
      state.mode = 'add'
      state.product = {
        id: null,
        title: '',
        logo: null,
        logoRaw: null,
        image: null,
        imageRaw: null,
        brand: '',
        email: '',
        shortDescription: '',
        description: '',
        ytId: '',
        usp: '',
        galleryRaw: []
      }
      state.galleryPreviews = []
      state.previewLogo = null
      state.previewImage = null
      fileLogo.value.value = ''
      fileImage.value.value = ''
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
      updateEditor()
    }

    const updateEditor = () => {
      editorShortDescription.value.getQuill().enable(false)
      editorShortDescription.value.setHTML(state.product.shortDescription)
      editorDescription.value.getQuill().enable(false)
      editorDescription.value.setHTML(state.product.description)
      editorUsp.value.getQuill().enable(false)
      editorUsp.value.setHTML(state.product.usp)
      // activate all
      nextTick(() => {
        editorShortDescription.value.getQuill().enable(true)
        editorDescription.value.getQuill().enable(true)
        editorUsp.value.getQuill().enable(true)
      })
    }

    const searchedItems = computed(() => state.products.filter((item) => (
      item.title.toLowerCase().indexOf(state.search.toLowerCase()) != -1
    )))

    const createPreviewLogo = (logo) => {
      const reader = new FileReader()
      reader.onload = (e) => state.previewLogo = e.target.result
      reader.readAsDataURL(logo)
    }

    const removePreviewLogo = (e) => {
      e.preventDefault()
      fileLogo.value.value = ''
      state.product.logoRaw = null
      state.previewLogo = null
    }

    const onLogoChange = (e) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Logo ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.product.logoRaw = null
        return
      }
      state.product.logoRaw = files[0]
      createPreviewLogo(files[0])
    }

    const createPreviewImage = (image) => {
      const reader = new FileReader()
      reader.onload = (e) => state.previewImage = e.target.result
      reader.readAsDataURL(image)
    }

    const removePreviewImage = (e) => {
      e.preventDefault()
      fileImage.value.value = ''
      state.product.imageRaw = null
      state.previewImage = null
    }

    const onImageChange = (e) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Bild ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.product.imageRaw = null
        return
      }
      state.product.imageRaw = files[0]
      createPreviewImage(files[0])
    }

    const addMedia    = (type) => type.push('')
    const removeMedia = (e, index) => {
      e.preventDefault()
      state.product.galleryRaw.splice(index, 1)
      state.galleryPreviews.splice(index, 1)
    }

    const onMediaChange = (e, index) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Bild ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.product.galleryRaw[index] = null
        return
      }
      state.product.galleryRaw[index] = files[0]
      createPreviewMedia(files[0], index)
    }

    const createPreviewMedia = (image, index) => {
      const reader = new FileReader()
      state.galleryPreviews.push('')
      reader.onload = (e) => state.galleryPreviews[index] = e.target.result
      reader.readAsDataURL(image)
    }

    const errorMsg = (error) => {
      createToast(
        {
          title: 'Es gab einen Fehler!',
          description: '' + error
        },
        {
          type: 'danger',
          position: 'bottom-right',
          showIcon: true
        }
      )
      emitter.emit('loading-stop')
    }

    // CANCEL
    const cancelEdit = () => {
      clear()
      createToast(
        {
          title: 'Bearbeitung abgebrochen!'
        },
        { type: 'default', position: 'bottom-right', showIcon: true }
      )
    }

    // GET
    const getProducts = () => {
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}products`)
        .then(response => {
          state.products = response.data
          createToast(
            'Produkte geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
        })
        .catch(error => errorMsg(error))
    }

    // ADD
    const addProduct = () => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}products`, buildFormData(state.product))
        .then(() => {
          createToast(
            'Produkt hinzugefügt!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getProducts()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // EDIT
    const editProduct = (id) => {
      emitter.emit('loading-start')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}products/${id}`)
        .then((response) => {
          state.mode = 'edit'
          state.product = response.data
          updateEditor()
          createToast(
            {
              title: 'Produkt geladen!',
              description: 'Nutzen Sie das Formular zum Editieren!'
            },
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // UPDATE
    const updateProduct = (id) => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}products/${id}`, buildFormData(state.product))
        .then(() => {
          createToast(
            'Produkt geändert!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getProducts()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // DELETE
    const deleteProduct = (id) => {
      if (confirm(`Produkt wirklich löschen?`)) {
        emitter.emit('loading-start')
        axios
          .delete(`${process.env.VUE_APP_API_ENDPOINT}products/${id}`)
          .then(() => {
            createToast(
              'Produkt gelöscht!',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            getProducts()
            clear()
            emitter.emit('loading-stop')
          })
          .catch(error => errorMsg(error))
      }
    }

    onMounted(() => {
      getProducts()
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
    })

    return {
      searchedItems,
      state,
      top,
      fileLogo,
      fileImage,
      createPreviewLogo,
      removePreviewLogo,
      onLogoChange,
      createPreviewImage,
      removePreviewImage,
      onImageChange,
      addMedia,
      removeMedia,
      cancelEdit,
      getProducts,
      addProduct,
      editProduct,
      updateProduct,
      deleteProduct,
      onMediaChange,
      editorShortDescription,
      editorDescription,
      editorUsp
    }
  }
}
</script>


<style lang="scss" scoped>
.admin-products {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.products {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background: linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147));
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: rgb(0, 0, 50), $alpha: 0.1);
    position: relative;
    overflow: hidden;
    transition: all 150ms ease-in-out;
    border-bottom: 1pt solid rgba($color: #000, $alpha: 0.1);

    b {
      position: absolute;
      right: 15%;
      bottom: -40px;
      color: rgba($color: #fff, $alpha: 0.1);
      font-size: 128px;
      line-height: 100%;
      z-index: 0;
      pointer-events: none;
    }

    span {
      background-color: #fff;
      padding: 3px 10px 1px 10px;
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      font-weight: 800;
      border-radius: 10px;
      margin: 0 10px 8px 0;
      text-transform: uppercase;
    }

    &:hover {
      box-shadow: 0 0 30px rgba($color: rgb(0, 0, 50), $alpha: 0.3);
      transform: scale(1.025);

      .toolbar {
        transform: translate(-50%, 0%);
      }
    }
  }
}

.preview-image {
  position: relative;
  pointer-events: none;

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 150ms ease-in-out;
    background: #fff;
    border-radius: 50%;
    pointer-events: all;
    z-index: 10;

    path {
      fill: #e24545;
    }

    &:hover {
      path {
        fill: lighten(#e24545, 20%);
      }
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 50, 0.75);
  padding: 10px 5px;
  transition: all 150ms ease-in-out;
  transform: translate(-50%, -100%);
  border-radius: 0 0 10px 10px;
}

.media-container {
  display: flex;
  border-bottom: 10px solid #f5f5f5;
  margin: 10px;
  padding: 10px 0 30px 0;

  .preview-image {
    width: 160px;
    margin-right: 15px;
    object-fit: contain;
    background: #f5f5f5;
    display: flex;
    padding: 5px;
    border-radius: 10px;

    img {
      display: block;
      width: 100%;
      max-height: 76px;
      object-fit: contain;
    }
  }

  .btn-remove {
    right: -40px !important;
    top: 16px !important;
  }

  .inputs {
    input {
      width: 100%;
      margin: 0 0 10px 0;
      padding: 8px 10px 6px 10px;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
