<template>
  <div class="admin">
    <nav>
      <div class="brand">⚡ Admin Interface Zukunftswerkstatt 4.0</div>
      <svg viewBox="0 0 448 512" title="Logout">
        <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
      </svg>
    </nav>
    <aside>
      <h5>Menü</h5>
      <ul>
        <li
            @click="component = `AdminTopics`"
            :class="{ active: component === `AdminTopics` }">
          <span class="icon">⭗</span>
          Themen
        </li>
        <li
            @click="component = `AdminProcesses`"
            :class="{ active: component === `AdminProcesses` }">
          <span class="icon">⭗</span>
          Prozessschritte
        </li>
        <li
            @click="component = `AdminInnovations`"
            :class="{ active: component === `AdminInnovations` }">
          <span class="icon">⭗</span>
          Innovationsfelder
        </li>
        <li
            @click="component = `AdminProducts`"
            :class="{ active: component === `AdminProducts` }">
          <span class="icon">⭗</span>
          Produkte
        </li>
      </ul>
      <p class="copyright">
        © touchwert<sup>®</sup>
        {{ new Date().getFullYear() }}
      </p>
    </aside>
    <div style="position: relative">
      <transition
          name="fade">
        <component :is="component" />
      </transition>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'
import AdminProcesses from '@/views/AdminProcesses'
import AdminInnovations from '@/views/AdminInnovations'
import AdminProducts from '@/views/AdminProducts'
import AdminTopics from '@/views/AdminTopics'

export default {
  name: 'Admin',

  components: {
    AdminProcesses,
    AdminInnovations,
    AdminProducts,
    AdminTopics
  },

  setup() {
    const component = ref('AdminTopics')

    return {
      component
    }
  }
}
</script>


<style lang="scss">
$animationDuration: 0.35s; /* Default value: 1s */
@import 'vue2-animate/src/sass/vue2-animate';

.list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-left: 320px;
  padding-right: 30px;
  color: rgba($color: #000, $alpha: 0.9);

  nav {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 25%);
    background-color: rgba($color: #1b222b, $alpha: 0.96);
    color: #fff;
    padding: 20px 30px;
    z-index: 10;
    display: flex;

    .brand {
      font-weight: 900;
      margin-right: auto;
    }

    svg {
      height: 32px;

      path {
        fill: rgba($color: #fff, $alpha: 0.75);
      }
    }
  }

  aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    background-color: #272f3b;
    color: #fff;
    padding: 100px 30px 30px 30px;
    display: flex;
    flex-direction: column;

    h5 {
      color: rgba($color: #fff, $alpha: 0.25);
      margin-bottom: 15px;
    }

    ul {
      @extend .list-reset;

      li {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        padding: 15px 0;
        cursor: pointer;
        transition: all 250ms ease-in-out;
        position: relative;

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background: #272f3b;
          position: absolute;
          right: -42px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          display: none;
        }

        &:last-child {
          border: none;
        }

        span {
          margin-right: 5px;
          font-weight: bold;
          color: #ffc60a;
        }

        &:hover {
          color: rgba($color: #fff, $alpha: 0.5);

          &::before {
            display: block;
          }
        }

        &.active {
          color: #ffc60a;

          &::before {
            display: block;
          }
        }
      }
    }

    .copyright {
      margin-top: auto;
      color: rgba($color: #fff, $alpha: 0.25);
      font-size: 14px;
    }
  }
}

h1 {
  margin: 60px 30px;
  font-weight: 300;
  letter-spacing: -1px;
}

.col {
  display: flex;

  .row {
    width: 50%;
    padding: 0 30px 40px 30px;
  }
}

.admin-search {
  font-family: 'Overpass', 'Neue Helvetica', Helvetica, Arial, sans-serif;
  display: block;
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 1.25rem;
  line-height: 100%;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid rgba($color: #000, $alpha: 1);
  background: #272f3b;
  color: #fff;
  box-shadow:
    inset 0.1rem 0.1rem 0.3rem rgba($color: #003, $alpha: 1),
    inset -0.05rem -0.05rem 0.1rem rgba($color: #003, $alpha: 1);

}

.admin-form {
  width: 100%;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.1);

  label {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    &.checkbox {
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 12px;
      cursor: pointer;

      &.last {
        margin-bottom: 50px;
      }

      span {
        font-size: 20px;
        color: #222;
        font-weight: 600;
        width: auto;
      }

      input[type="checkbox"],
      input[type="radio"] {
        width: 20px;
        height: 20px;
        margin: 0 0 0 10px;
      }
    }

    > span {
      font-size: 27px;
      font-weight: 700;
      width: 100%;
      margin: 0 10px;
      //color: rgb(70, 0, 160);

      small {
        font-size: 14px;
        display: block;
        color: #9ea9bb;

        b {
          color: #7f8999;
          font-weight: 900;
        }
      }
    }

    > p {
      margin: 10px 10px 30px 10px;
      color: #555;
    }

    .img {
      display: block;
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 5px;
    }

    input[type="text"],
    input[type="number"],
    input[type="file"],
    select,
    textarea {
      font-family: 'Overpass', 'Neue Helvetica', Helvetica, Arial, sans-serif;
      flex: 1;
      width: 100%;
      margin: 15px 10px 0 10px;
      height: 8.75rem;
      padding: 10px 15px;
      font-size: 1rem;
      line-height: 150%;
      border-radius: 10px;
      border: 1px solid rgba($color: #000, $alpha: 0.1);
      color: #3d4249;
      box-shadow:
        inset 1px 1px 2px #a5bac773,
        inset -2px -2px 2px #ffffffcc;
    }

    input[type="text"],
    input[type="number"],
    select {
      height: auto;
      font-weight: 600;
    }

    input[type="file"] {
      height: auto;
    }

    .input-container,
    .media-container {
      width: 100%;
      position: relative;

      input {
        width: calc(100% - 20px);
      }

      .btn-remove {
        position: absolute;
        top: 24px;
        right: -30px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: opacity 150ms ease-in-out;

        path {
          fill: #e24545;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .btn-add {
      width: 42px;
      height: 42px;
      margin: 10px;
      cursor: pointer;
      transition: opacity 150ms ease-in-out;

      path {
        fill: #3e9927;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .multiselect-container {
    margin: -30px 10px 60px 10px;
  }

  .editor {
    margin: -30px 10px 50px 10px;
    border-radius: 10px;
    border: 1px solid rgba($color: #000, $alpha: 0.1);
    box-shadow:
        inset 1px 1px 2px #a5bac773,
        inset -2px -2px 2px #ffffffcc;
  }
}

.buttons-admin {
  display: flex;
}

.btn-admin {
  width: 100%;
  background-color: #ffdb0a;
  border: none;
  padding: 15px;
  margin: 0 10px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  span {
    line-height: 100%;
    vertical-align: top;
    display: inline-block;
    margin-right: 8px;
    transform: scale(1.75);
  }

  &.btn-admin-cancel {
    background-color: #ddd;
  }

  &.btn-admin-danger {
    background-color: #e74e4e;
  }

  &.btn-admin-sm {
    margin: 0 5px;
    padding: 4px 10px 6px 10px;
    font-size: 13px;
    font-weight: 700;
    width: auto;
  }

  &:hover {
    opacity: 0.5;
  }
}

.multiselect-container {
  .multiselect-input {
    padding: 9px 10px 8px 10px;
    font-size: 1rem;
    line-height: 150%;
    border-radius: 10px;
    border: 1px solid rgba($color: #000, $alpha: 0.1);
    color: #3d4249;
    box-shadow:
      inset 0.1rem 0.1rem 0.2rem #a5bac773,
      inset -0.1rem -0.1rem 0.2rem #ffffffcc;
  }

  .multiselect-tags {
    position: relative;
    overflow: hidden;
  }

  .multiselect-tag {
    font-size: 15px;
    font-weight: 600;
    padding: 2px 4px 0px 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 700;

    i::before {
      font-size: 18px;
      line-height: 20px;
      padding: 2px 5px 0 5px;
      font-weight: 900;
    }
  }

  .multiselect-options {
    margin-top: 5px;
    border-radius: 5px;
  }
}

.ql-container {
  font-size: inherit !important;

  h1 {
    font-size: 27px !important;
    font-weight: 700;
  }

  p, ul, h1 {
    margin: 0 0 1.5rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
