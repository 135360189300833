<template>
  <form
      class="admin-form"
      style="margin-top: 60px"
      @submit.prevent>
    <label v-if="state.media.length !== 0">
      <span>
        Bildergalerie bearbeiten
        <small>
          Die Bildergalerie arbeitet in Echtzeit und muss nicht
          über das obere Formular gespeichert werden.
        </small>
      </span>
      <br>
      <div
          v-for="image in state.media"
          :key="image"
          class="media">
        <img
            :src="`${state.basepath}images/${image.title}`"
            class="img">
        <svg
            class="btn-remove"
            viewBox="0 0 24 24"
            @click="removeMedia(image.title)">
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
        </svg>
      </div>
    </label>
    <label style="margin-bottom: 0">
      <span>
        Bild zur Galerie hinzufügen
        <small>Format: .jpg/ Größe: max 0.5 MB</small>
      </span>
      <input type="file" @change="onMediaChange" ref="fileMedia">
    </label>
  </form>
</template>


<script>
import { ref, reactive, onMounted, watch, getCurrentInstance } from 'vue'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  props: {
    productId: String
  },

  setup(props) {
    const instance = getCurrentInstance()
    const emitter = instance.appContext.config.globalProperties.emitter
    const axios = instance.appContext.config.globalProperties.axios
    const fileMedia = ref(null)

    const state = reactive({
      basepath: process.env.VUE_APP_API_ENDPOINT,
      file: {
        productId: props.productId,
        caption: '',
        imgOrder: 1,
        raw: null
      },
      media: []
    })

    const clear = () => {
      state.file.raw = null
      fileMedia.value.value = ''
    }

    const onMediaChange = (e) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Bild ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.file.raw = null
        return
      }

      state.file.raw = files[0]

      // ADD
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}media`, buildFormData(state.file))
        .then(() => {
          createToast(
            'Bild hinzugefügt!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getMedia(props.productId)
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    const errorMsg = (error) => {
      createToast(
        {
          title: 'Es gab einen Fehler!',
          description: '' + error
        },
        {
          type: 'danger',
          position: 'bottom-right',
          showIcon: true
        }
      )
      emitter.emit('loading-stop')
    }

    // GET
    const getMedia = (id) => {
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}media/${id}`)
        .then(response => state.media = response.data)
        .catch(error => errorMsg(error))
    }

    const removeMedia = (title) => {
      if (confirm(`Bild wirklich löschen?`)) {
        emitter.emit('loading-start')
        axios
          .delete(`${process.env.VUE_APP_API_ENDPOINT}media/${title}`)
          .then(() => {
            createToast(
              'Bild gelöscht!',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            // fetch media
            getMedia(props.productId)
            emitter.emit('loading-stop')
          })
          .catch(error => errorMsg(error))
      }
    }

    onMounted(() => getMedia(props.productId))
    watch(() => props.productId, () => {
      // update product id
      state.file.productId = props.productId
      // fetch media
      getMedia(props.productId)
    })

    return {
      state,
      fileMedia,
      onMediaChange,
      removeMedia
    }
  }
}
</script>


<style lang="scss" scoped>
.media {
  position: relative;
}

.btn-remove {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
  background: #fff;
  border-radius: 50%;

  path {
    fill: #e24545;
  }

  &:hover {
    path {
      fill: #e5b6b6;
    }
  }
}
</style>
