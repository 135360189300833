<template>
  <div class="admin-processes">
    <h1 ref="top">
      ✎ <b>Prozessschritt</b> hinzufügen oder bearbeiten ({{ state.processes.length }})
    </h1>
    <div class="col">
      <div class="row">
        <form class="admin-form" @submit.prevent>
          <label>
            <span>Titel</span>
            <input v-model="state.process.title" type="text" />
          </label>
          <label>
            <span>
              Reihenfolge
              <small>Einfache Zahl, z.B. 1</small>
            </span>
            <input v-model="state.process.order_id" type="number" />
          </label>
          <label class="checkbox">
            <span>Sales</span>
            <input v-model="state.process.area" value="sales" type="radio" />
          </label>
          <label class="checkbox last">
            <span>After Sales</span>
            <input v-model="state.process.area" value="aftersales" type="radio" />
          </label>
          <label>
            <span>
              Bild
              <small>Auflösung: 1152*720 px/ Format: .jpg/ Größe: max 0.5 MB</small>
            </span>
            <img
                :src="`${state.basepath}images/${state.process.image}`"
                :alt="state.process.title"
                class="img"
                v-if="state.process.image && !state.previewImage">
            <div
                class="preview-image"
                v-if="state.previewImage">
              <img
                  :src="state.previewImage"
                  class="img" />
              <svg
                  class="btn-remove"
                  @click="removePreviewImage"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <input type="file" @change="onFileChange" ref="file">
          </label>
          <label>
            <span>
              Position Touchpoint
              <small>X- und Y-Wert, prozentual und relativ zum Viewport</small>
            </span>
            <input v-model="state.process.x" type="number" placeholder="x" />
            <input v-model="state.process.y" type="number" placeholder="y" />
          </label>
          <label>
            <span>
              Beschreibung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorDescription"
                v-model:content.value="state.process.description" />
          </div>
          <label>
            <span>
              Keyfacts
              <small>optional</small>
            </span>
            <div
                v-for="(input, index) in state.process.keyfacts"
                :key="`fact-${index}`"
                class="input-container">
              <input v-model="state.process.keyfacts[index]" type="text" />
              <svg
                  class="btn-remove"
                  @click="removeFact(index, state.process.keyfacts)"
                  viewBox="0 0 24 24">
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
              </svg>
            </div>
            <svg
                class="btn-add"
                @click="addFact(state.process.keyfacts)"
                viewBox="0 0 24 24">
              <path d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
            </svg>
          </label>
          <label>
            <span>
              Ziele
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorAims"
                v-model:content.value="state.process.aims" />
          </div>
          <label>
            <span>
              Veränderungstreiber
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorChanges"
                v-model:content.value="state.process.changes" />
          </div>
          <label>
            <span>
              Anwendung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorUsage"
                v-model:content.value="state.process.usage" />
          </div>
          <label>
            <span>
              Vorteile
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorAdvantages"
                v-model:content.value="state.process.advantages" />
          </div>
          <label>
            <span>
              Innnovationsfelder
              <small>Mehrfachauswahl möglich</small>
            </span>
          </label>
          <div class="multiselect-container multiselect-innovations">
            <Multiselect
                mode="tags"
                placeholder="Innnovationsfelder auswählen"
                :searchable="true"
                valueProp="id"
                label="title"
                track-by="title"
                v-model="state.process.innovations"
                :options="state.innovations">
              <template v-slot:nooptions>
                <div class="multiselect-no-options">
                  Es wurden noch keine Innovationsfelder angelegt.
                </div>
              </template>
              <template v-slot:noresults>
                <div class="multiselect-no-results">
                  Keine Ergebnisse gefunden :(
                </div>
              </template>
            </Multiselect>
          </div>
          <div class="buttons-admin">
            <button
                class="btn-admin"
                v-if="state.mode === 'add'"
                @click="addProcess()">
              <span>＋</span> Hinzufügen
            </button>
            <button
                class="btn-admin"
                v-if="state.mode === 'edit'"
                @click="updateProcess(state.process.id)">
              <span>✔</span> Speichern
            </button>
            <button
                class="btn-admin btn-admin-cancel"
                v-if="state.mode === 'edit'"
                @click="cancelEdit()">
              <span>✖</span> Abbrechen
            </button>
          </div>
        </form>
      </div>
      <div class="row">
        <p v-if="state.processes.length === 0">
          Keine Prozessschritte angelegt.
        </p>
        <input
            v-if="state.processes.length !== 0"
            type="text"
            placeholder="Suche..."
            v-model="state.search"
            class="admin-search" />
        <ul class="processes">
          <li v-for="(process, index) in searchedItems" :key="process.id">
            <span v-if="process.area === 'sales'">Sales</span>
            <span v-if="process.area === 'aftersales'">After Sales</span>
            <h3>{{ process.title }}</h3>
            <b>{{ index + 1 }}</b>
            <div class="buttons-admin toolbar">
              <button
                  class="btn-admin btn-admin-sm"
                  @click="editProcess(process.id)">✎ Editieren</button>
              <button
                  class="btn-admin btn-admin-sm btn-admin-danger"
                  @click="deleteProcess(process.id)">✖ Löschen</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive, ref, computed, nextTick } from 'vue'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// https://github.com/vueform/multiselect
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  name: 'Admin processes',

  components: {
    Multiselect
  },

  setup() {
    const instance = getCurrentInstance()
    const emitter = instance.appContext.config.globalProperties.emitter
    const axios = instance.appContext.config.globalProperties.axios
    const top = ref(null)
    const file = ref(null)
    const editorDescription = ref(null)
    const editorAims = ref(null)
    const editorChanges = ref(null)
    const editorUsage = ref(null)
    const editorAdvantages = ref(null)

    const state = reactive({
      basepath: process.env.VUE_APP_API_ENDPOINT,
      mode: 'add',
      search: '',
      processes: [],
      process: {
        id: null,
        title: '',
        order_id: '',
        image: null,
        imageRaw: null,
        x: 0,
        y: 0,
        keyfacts: [],
        innovations: [],
        area: '',
        description: '',
        aims: '',
        changes: '',
        usage: '',
        advantages: ''
      },
      innovations: [],
      previewImage: null
    })

    const clear = () => {
      state.mode = 'add'
      state.process = {
        id: null,
        title: '',
        order_id: '',
        image: null,
        imageRaw: null,
        x: 0,
        y: 0,
        keyfacts: [],
        innovations: [],
        area: '',
        description: '',
        aims: '',
        changes: '',
        usage: '',
        advantages: ''
      }
      state.previewImage = null
      file.value.value = ''
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
      updateEditor()
    }

    const updateEditor = () => {
      editorDescription.value.getQuill().enable(false)
      editorDescription.value.setHTML(state.process.description)
      editorAims.value.getQuill().enable(false)
      editorAims.value.setHTML(state.process.aims)
      editorChanges.value.getQuill().enable(false)
      editorChanges.value.setHTML(state.process.changes)
      editorUsage.value.getQuill().enable(false)
      editorUsage.value.setHTML(state.process.usage)
      editorAdvantages.value.getQuill().enable(false)
      editorAdvantages.value.setHTML(state.process.advantages)
      // activate all
      nextTick(() => {
        editorDescription.value.getQuill().enable(true)
        editorAims.value.getQuill().enable(true)
        editorChanges.value.getQuill().enable(true)
        editorUsage.value.getQuill().enable(true)
        editorAdvantages.value.getQuill().enable(true)
      })
    }

    const searchedItems = computed(() => state.processes.filter((item) => (
      item.title.toLowerCase().indexOf(state.search.toLowerCase()) != -1
    )))

    const addFact    = (type) => type.push('')
    const removeFact = (index, type) => type.splice(index, 1)

    const createPreviewImage = (file) => {
      const reader = new FileReader()
      reader.onload = (e) => state.previewImage = e.target.result
      reader.readAsDataURL(file)
    }

    const removePreviewImage = (e) => {
      e.preventDefault()
      file.value.value = ''
      state.process.imageRaw = null
      state.previewImage = null
    }

    const onFileChange = (e) => {
      const maxAllowedSize = 0.5 * 1024 * 1024
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      // check size and type
      if(files[0].size > maxAllowedSize  || files[0].type !== 'image/jpeg') {
        createToast(
          'Das Bild ist zu groß oder nicht vom Typ .jpg/ .jpeg!',
          { type: 'danger', position: 'bottom-right', showIcon: true }
        )
        e.target.value = ''
        state.process.imageRaw = null
        return
      }
      state.process.imageRaw = files[0]
      createPreviewImage(files[0])
    }

    const errorMsg = (error) => {
      createToast(
        {
          title: 'Es gab einen Fehler!',
          description: '' + error
        },
        {
          type: 'danger',
          position: 'bottom-right',
          showIcon: true
        }
      )
      emitter.emit('loading-stop')
    }

    // GET
    const getProcesses = async () => {
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}processes`)
        .then(response => {
          state.processes = response.data

          createToast(
            'Prozessschritte geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
        })
        .catch(error => errorMsg(error))
    }

    // ADD
    const addProcess = () => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}processes`, buildFormData(state.process))
        .then(() => {
          createToast(
            'Prozessschritt hinzugefügt!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getProcesses()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // EDIT
    const editProcess = (id) => {
      emitter.emit('loading-start')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}processes/${id}`)
        .then((response) => {
          state.mode = 'edit'
          state.process = response.data
          updateEditor()
          createToast(
            {
              title: 'Prozessschritt geladen!',
              description: 'Nutzen Sie das Formular zum Editieren!'
            },
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // UPDATE
    const updateProcess = (id) => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}processes/${id}`, buildFormData(state.process))
        .then(() => {
          createToast(
            'Prozessschritt geändert!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          getProcesses()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // CANCEL
    const cancelEdit = () => {
      clear()
      createToast(
        {
          title: 'Bearbeitung abgebrochen!'
        },
        { type: 'default', position: 'bottom-right', showIcon: true }
      )
    }

    // DELETE
    const deleteProcess = (id) => {
      if (confirm(`Prozessschritt wirklich löschen?`)) {
        emitter.emit('loading-start')
        axios
          .delete(`${process.env.VUE_APP_API_ENDPOINT}processes/${id}`)
          .then(() => {
            createToast(
              'Prozessschritt gelöscht!',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            clear()
            getProcesses()
            emitter.emit('loading-stop')
          })
          .catch(error => errorMsg(error))
      }
    }

    // GET INNOVATIONS
    const getInnovations = async () => {
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}innovations`)
        .then(response => state.innovations = response.data)
        .catch(error => errorMsg(error))
    }

    onMounted(() => {
      getProcesses()
      getInnovations()
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
    })

    return {
      searchedItems,
      top,
      file,
      onFileChange,
      removePreviewImage,
      state,
      addFact,
      removeFact,
      clear,
      addProcess,
      editProcess,
      cancelEdit,
      updateProcess,
      deleteProcess,
      editorDescription,
      editorAims,
      editorChanges,
      editorUsage,
      editorAdvantages
    }
  }
}
</script>


<style lang="scss" scoped>
.admin-processes {
  position: absolute;
  top: 0;
  left: 0;
}

.processes {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background: rgb(162, 174, 197);
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: rgb(0, 0, 50), $alpha: 0.1);
    position: relative;
    overflow: hidden;
    transition: all 150ms ease-in-out;
    border-bottom: 2px solid rgba($color: #000, $alpha: 0.125);

    b {
      position: absolute;
      right: 15%;
      bottom: -40px;
      color: rgba($color: #fff, $alpha: 0.1);
      font-size: 128px;
      line-height: 100%;
      z-index: 0;
      pointer-events: none;
    }

    span {
      background-color: #fff;
      padding: 3px 10px 1px 10px;
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      font-weight: 800;
      border-radius: 10px;
      margin: 0 10px 8px 0;
      text-transform: uppercase;
    }

    &:hover {
      box-shadow: 0 0 30px rgba($color: rgb(0, 0, 50), $alpha: 0.3);
      transform: scale(1.025);

      .toolbar {
        transform: translate(-50%, 0%);
      }
    }
  }
}

.preview-image {
  position: relative;
  pointer-events: none;

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: opacity 150ms ease-in-out;
    background: #fff;
    border-radius: 50%;
    pointer-events: all;
    z-index: 10;

    path {
      fill: #e24545;
    }

    &:hover {
      path {
        fill: lighten(#e24545, 20%);
      }
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 50, 0.75);
  padding: 10px 5px;
  transition: all 150ms ease-in-out;
  transform: translate(-50%, -100%);
  border-radius: 0 0 10px 10px;
}

.multiselect-container.multiselect-innovations {
  :deep(.multiselect-tag) {
    background: linear-gradient(to right, rgb(255, 148, 114), rgb(242, 112, 156));
    color: #000;

    i::before {
      color: #000;
    }
  }
}
</style>
