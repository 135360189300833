<template>
  <div class="admin-topics">
    <h1 ref="top">
      ✎ <b>Thema</b> hinzufügen oder bearbeiten ({{ state.topics.length }})
    </h1>
    <div class="col">
      <div class="row">
        <form class="admin-form" @submit.prevent>
          <label>
            <span>Titel</span>
            <input v-model="state.topic.title" type="text" />
          </label>
          <label>
            <span>
              Reihenfolge
              <small>Einfache Zahl, z.B. 1</small>
            </span>
            <input v-model="state.topic.order_id" type="number" />
          </label>
          <label>
            <span>
              Zuordnung
              <small>Bitte Themenfeld auswählen</small>
            </span>
            <select v-model="state.topic.parent">
              <option disabled value="">Bitte Themenfeld auswählen</option>
              <option value="marktentwicklung">Marktentwicklung: Geschäfts- und Vertriebsmodelle sowie Wettbewerber</option>
              <option value="connected_car">Connected Car / Autonomous Car</option>
              <option value="neukunden">Neue Kund:innen</option>
              <option value="ladeinfrastruktur_tankinfrastruktur">Ladeinfrastruktur & Tankinfrastruktur</option>
              <option value="neue_antriebsvarianten">Neue Antriebsvarianten</option>
              <option value="fachkraefte_und_beschaeftigung">Fachkräfte und Beschäftigung</option>
            </select>
          </label>
          <label>
            <span>
              Beschreibung
              <small>400 - 600 Zeichen</small>
            </span>
          </label>
          <div class="editor">
            <QuillEditor
                theme="bubble"
                contentType="html"
                ref="editorDescription"
                v-model:content.value="state.topic.description" />
          </div>
          <div class="buttons-admin">
            <button
                class="btn-admin"
                v-if="state.mode === 'add'"
                @click="addTopic()">
              <span>＋</span> Hinzufügen
            </button>
            <button
                class="btn-admin"
                v-if="state.mode === 'edit'"
                @click="updateTopic(state.topic.id)">
              <span>✔</span> Speichern
            </button>
            <button
                class="btn-admin btn-admin-cancel"
                v-if="state.mode === 'edit'"
                @click="cancelEdit()">
              <span>✖</span> Abbrechen
            </button>
          </div>
        </form>
      </div>
      <div class="row">
        <p v-if="state.topics.length === 0">
          Keine Themen angelegt.
        </p>
        <input
            v-if="state.topics.length !== 0"
            type="text"
            placeholder="Suche..."
            v-model="state.search"
            class="admin-search" />
        <ul class="topics">
          <li v-for="(topic, index) in searchedItems" :key="topic.id">
            <span v-if="topic.parent === 'marktentwicklung'">Marktentwicklung</span>
            <span v-if="topic.parent === 'connected_car'">Connected Car / Autonomous Car</span>
            <span v-if="topic.parent === 'neukunden'">Neue Kund:innen</span>
            <span v-if="topic.parent === 'ladeinfrastruktur_tankinfrastruktur'">Ladeinfrastruktur & Tankinfrastruktur</span>
            <span v-if="topic.parent === 'neue_antriebsvarianten'">Neue Antriebsvarianten</span>
            <span v-if="topic.parent === 'fachkraefte_und_beschaeftigung'">Fachkräfte und Beschäftigung</span>
            <h3>{{ topic.title }}</h3>
            <b>{{ index + 1 }}</b>
            <div class="buttons-admin toolbar">
              <button
                  class="btn-admin btn-admin-sm"
                  @click="editTopic(topic.id)">✎ Editieren</button>
              <button
                  class="btn-admin btn-admin-sm btn-admin-danger"
                  @click="deleteTopic(topic.id)">✖ Löschen</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import { onMounted, getCurrentInstance, reactive, ref, computed, nextTick } from 'vue'
import { buildFormData } from '@/tools.js'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'Admin topics',

  setup() {
    const instance = getCurrentInstance()
    const emitter = instance.appContext.config.globalProperties.emitter
    const axios = instance.appContext.config.globalProperties.axios
    const top = ref(null)
    const editorDescription = ref(null)

    const state = reactive({
      basepath: process.env.VUE_APP_API_ENDPOINT,
      mode: 'add',
      search: '',
      topics: [],
      topic: {
        id: null,
        title: '',
        order_id: '',
        description: ''
      }
    })

    const clear = () => {
      state.mode = 'add'
      state.topic = {
        id: null,
        title: '',
        order_id: '',
        description: ''
      }
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
      updateEditor()
    }

    const updateEditor = () => {
      editorDescription.value.getQuill().enable(false)
      editorDescription.value.setHTML(state.topic.description)
      // activate all
      nextTick(() => {
        editorDescription.value.getQuill().enable(true)
      })
    }

    const searchedItems = computed(() => state.topics.filter((item) => (
      item.title.toLowerCase().indexOf(state.search.toLowerCase()) != -1
    )))

    const errorMsg = (error) => {
      createToast(
        {
          title: 'Es gab einen Fehler!',
          description: '' + error
        },
        {
          type: 'danger',
          position: 'bottom-right',
          showIcon: true
        }
      )
      emitter.emit('loading-stop')
    }

    // GET
    const gettopics = async () => {
      await axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}topics`)
        .then(response => {
          state.topics = response.data

          createToast(
            'Themen geladen!',
            { type: 'default', position: 'bottom-right', showIcon: true }
          )
        })
        .catch(error => errorMsg(error))
    }

    // ADD
    const addTopic = () => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}topics`, buildFormData(state.topic))
        .then(() => {
          createToast(
            'Thema hinzugefügt!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          gettopics()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // EDIT
    const editTopic = (id) => {
      emitter.emit('loading-start')
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}topics/${id}`)
        .then((response) => {
          state.mode = 'edit'
          state.topic = response.data
          updateEditor()
          createToast(
            {
              title: 'Thema geladen!',
              description: 'Nutzen Sie das Formular zum Editieren!'
            },
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // UPDATE
    const updateTopic = (id) => {
      emitter.emit('loading-start')
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}topics/${id}`, buildFormData(state.topic))
        .then(() => {
          createToast(
            'Thema geändert!',
            { type: 'success', position: 'bottom-right', showIcon: true }
          )
          gettopics()
          clear()
          emitter.emit('loading-stop')
        })
        .catch(error => errorMsg(error))
    }

    // CANCEL
    const cancelEdit = () => {
      clear()
      createToast(
        {
          title: 'Bearbeitung abgebrochen!'
        },
        { type: 'default', position: 'bottom-right', showIcon: true }
      )
    }

    // DELETE
    const deleteTopic = (id) => {
      if (confirm(`Thema wirklich löschen?`)) {
        emitter.emit('loading-start')
        axios
          .delete(`${process.env.VUE_APP_API_ENDPOINT}topics/${id}`)
          .then(() => {
            createToast(
              'Thema gelöscht!',
              { type: 'success', position: 'bottom-right', showIcon: true }
            )
            clear()
            gettopics()
            emitter.emit('loading-stop')
          })
          .catch(error => errorMsg(error))
      }
    }

    onMounted(() => {
      gettopics()
      top.value.scrollIntoView({
        behavior: 'smooth',
        block: "end"
      })
    })

    return {
      searchedItems,
      top,
      state,
      clear,
      addTopic,
      editTopic,
      cancelEdit,
      updateTopic,
      deleteTopic,
      editorDescription
    }
  }
}
</script>


<style lang="scss" scoped>
.admin-topics {
  position: absolute;
  top: 0;
  left: 0;
}

.topics {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    background: rgb(162, 174, 197);
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: rgb(0, 0, 50), $alpha: 0.1);
    position: relative;
    overflow: hidden;
    transition: all 150ms ease-in-out;
    border-bottom: 2px solid rgba($color: #000, $alpha: 0.125);

    b {
      position: absolute;
      right: 15%;
      bottom: -40px;
      color: rgba($color: #fff, $alpha: 0.1);
      font-size: 128px;
      line-height: 100%;
      z-index: 0;
      pointer-events: none;
    }

    span {
      background-color: #fff;
      padding: 3px 10px 1px 10px;
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      font-weight: 800;
      border-radius: 10px;
      margin: 0 10px 8px 0;
      text-transform: uppercase;
    }

    &:hover {
      box-shadow: 0 0 30px rgba($color: rgb(0, 0, 50), $alpha: 0.3);
      transform: scale(1.025);

      .toolbar {
        transform: translate(-50%, 0%);
      }
    }
  }
}

.toolbar {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: rgba(0, 0, 50, 0.75);
  padding: 10px 5px;
  transition: all 150ms ease-in-out;
  transform: translate(-50%, -100%);
  border-radius: 0 0 10px 10px;
}
</style>
